import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const modes = [
  ['Music', 'MusicEntry.php', 'DashboardMusicToday.php', 'MusicDonut.php', 'MusicWeekMonth.php', 'MusicProficiencyProgressRefresh.php'],
  ['Tech', 'TechEntry.php', 'DashboardTechToday.php', 'TechDonut.php', 'TechWeekMonth.php', 'TechProficiency.php'],
  ['Lift', 'LiftDailyEntry.php', 'LiftDaily.php', 'LiftPieChart.php', 'LiftWeekly.php', 'LiftMonthly.php'],
  ['Nutr', '', '', '', 'LiftWeekly.php', 'LiftMonthly.php'],
  ['Daily', 'LiftDaily.php', 'DashboardMusicToday.php', 'DashboardTechToday.php', 'MusicDonut.php', 'TechDonut.php'],
  ['Stats', 'Weekly.php', 'DashboardMusicToday.php', 'DashboardTechToday.php', 'LiftWeeklyWeightBar.php', 'LiftWeeklyWeight.php']
];

function DraggableIFrame({ src, onDrop }) {
  const [{ isDragging }, ref] = useDrag({
    type: 'iframe',
    item: { src },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'iframe',
    drop: (item) => onDrop(item.src),
  });

  return (
    <div
      ref={(node) => {
        ref(drop(node));
      }}
      className={`iframe-container ${isDragging ? 'dragging' : ''}`}
    >
      <iframe
        src={src}
        className="embed-responsive-item"
        style={{ width: '99%', height: '99%', minHeight: '400px' }}
      ></iframe>
    </div>
  );
}

function Button({ label, onClick }) {
  return (
    <button className="btn" onClick={onClick}>
      {label}
    </button>
  );
}

function App() {
  const [activeMode, setActiveMode] = useState(0);
  const [droppedIframes, setDroppedIframes] = useState([]);

  const handleButtonClick = (index) => {
    setActiveMode(index);
  };

  const handleExercisesButtonClick = () => {
    // Handle exercises button click here
  };

  const handleReloadButtonClick = () => {
    // Handle reload button click here
  };

  const handleDrop = (src) => {
    // Handle dropped iframe here
    setDroppedIframes((prev) => [...prev, src]);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="navbar">
        {/* Add your navbar content here */}
      </div>
      <div className="date"></div>
      <div className="grid">
        {modes[activeMode].slice(1).map((src, index) => (
          <DraggableIFrame key={index} src={src} onDrop={handleDrop} />
        ))}
      </div>
      <div className="navbar-buttons">
        {modes.map(([label], index) => (
          <Button
            key={index}
            label={label}
            onClick={() => handleButtonClick(index)}
          />
        ))}
        <Button label="Exercises" onClick={handleExercisesButtonClick} />
        <Button label="Reload" onClick={handleReloadButtonClick} />
      </div>
      <div className="dropped-iframes">
        <h2>Dropped Iframes:</h2>
        <ul>
          {droppedIframes.map((src, index) => (
            <li key={index}>{src}</li>
          ))}
        </ul>
      </div>
    </DndProvider>
  );
}

export default App;
